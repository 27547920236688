import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { red } from '@material-ui/core/colors';
import {
  Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,
  Button,
} from '@material-ui/core';

const useStyle = makeStyles(theme => ({
  dangerSubmit: {
    color: red[500]
  }
}));

export const StandardDialog = ({
  open,
  onClose = null,
  onCancel = onClose,
  onSubmit = onClose,
  title,
  description,
  children,
  submitLabel,
  dangerSubmit,
  hideCancel,
}) => {
  const classes = useStyle();
  return (
    <Dialog
      open={open}
      onClose={onClose}
      keepMounted
    >
      {title && <DialogTitle>{title}</DialogTitle>}
      <DialogContent>
        {description && <DialogContentText>{description}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        {!hideCancel && (
          <Button
            onClick={onCancel}
            color="primary"
          >
            キャンセル
          </Button>
        )}
        <Button
          onClick={onSubmit}
          color="primary"
          className={ dangerSubmit ? classes.dangerSubmit : '' }
        >
          {submitLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

