import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Typography, Toolbar,
  Container, Paper,
  Button, IconButton,
  CircularProgress,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { runScript } from '~/appsscript';
import QrReader from 'react-qr-reader';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import './Entrance.css';

export const Entrance = withRouter(({
  title,
  checkPermission,
  hasAccess,
  ...props
}) => {
  const [ result, setResult ] = useState(null);
  const [ circle, setCircle ] = useState(null);
  const [ loading, setLoading ] = useState(false);
  const [ errorMessage, setErrorMessage ] = useState(null);
  const [ entering, setEntering ] = useState(false);
  const [ enteringErrorMessage, setEnteringErrorMessage ] = useState(null);

  useEffect(() => {
    checkPermission('GUEST');
    document.title = title;
  }, []);

  useEffect(() => {
    if(!hasAccess || !result || loading || entering) return;
    const circleId = result;
    console.log(circleId);
    setLoading(true);
    setErrorMessage(null);
    setCircle(null);
    runScript({
      functionName: 'findCircleById',
      parameters: [ circleId, true ]
    }).then(({ row: circle }) => {
      if(!circle) throw new Error('db/not-found');
      console.log(circle);
      setCircle(circle);
      setLoading(false);
    }).catch(err => {
      console.error(err);
      switch(err.message) {
        case 'db/not-found':
          setErrorMessage('サークルが見つかりません.');
          break;
      }
      setLoading(false);
    });
  }, [result]);

  const setEntered = id => {
    setEntering(true);
    setEnteringErrorMessage(null);
    runScript({
      functionName: 'setCircleEntered',
      parameters: [ id ]
    }).then(({ row: circle }) => {
      console.log(circle);
      setCircle(circle);
      setEntering(false);
    }).catch(err => {
      console.error(err);
      switch(err.message) {
        case 'circle/already-entered':
          setEnteringErrorMessage('入場済みです.');
          break;
        case 'db/not-found':
          setEnteringErrorMessage('サークルが見つかりません.');
          break;
      }
      setEntering(false);
    });
  };

  const formatDate = d => {
    return (
      d.getFullYear()
      + '/' + ('0' + (d.getMonth() + 1)).slice(-2)
      + '/' + ('0' + d.getDate()).slice(-2)
      + ' ' + ('0' + d.getHours()).slice(-2)
      + ':' + ('0' + d.getMinutes()).slice(-2)
      + ':' + ('0' + d.getSeconds()).slice(-2)
    );
  };

  const handleError = console.error;
  const handleScan = setResult;

  return (
    hasAccess ? (
      <div className="entrance">
        <div className="qr">
          <QrReader
            delay={300}
            onError={handleError}
            onScan={handleScan}
            className="qr-reader"
          />
          <Paper
            variant="outlined"
            className="qr-message"
          >
            {loading || entering ? (
              <Typography
                variant="h5"
                color="error"
              >
                しばらくお待ち下さい...
              </Typography>
            ) : (
              <Typography
                variant="h5"
                color="primary"
              >
                QR コードをかざしてください.
              </Typography>
            )}
          </Paper>
        </div>
        
        <div className="panel">
          <Toolbar>
            <Button
              onClick={() => window.location.href = '/'}
              startIcon={<ArrowBackIcon />}
            >
              戻る
            </Button>
          </Toolbar>

          <Container maxWidth="sm" className="panelContainer">
            {loading && (
              <CircularProgress
                size={64}
              />
            )}
  
            {circle && (
              <div className="panelCircle">
                <Typography variant="h5" component="h2">
                  <ruby>
                    {circle.name}
                    <rp><br />(</rp>
                    <rt>{circle.nameKana}</rt>
                    <rp>)</rp>
                  </ruby>
                </Typography>
                <Typography variant="h5" component="h4">
                  {circle.space.block === '' ? (
                    '(未配置)'
                  ) : (
                    <>{circle.space.block}-{('0'+circle.space.number).slice(-2)}</>
                  )}
                </Typography>
                <Typography variant="h6" component="h3">
                  {circle.representative.penName}
                  <br />{circle.representative.name}
                </Typography>
  
                {circle.entered ? (
                  <Button
                    variant="outlined"
                    fullWidth
                    size="large"
                    disabled
                  >
                    入場済み
                    <br />({formatDate(new Date(circle.entered))})
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    fullWidth
                    onClick={() => setEntered(circle.id)}
                    disabled={entering}
                  >
                    {entering ? '入場処理中...' : '入場'}
                  </Button>
                )}
  
                {enteringErrorMessage && (
                  <Typography
                    color="error"
                    variant="h6"
                    component="div"
                  >
                    {enteringErrorMessage}
                  </Typography>
                )}
              </div>
            )}
            
            {errorMessage && (
              <div className="panelStatus">
                <Typography
                  color="error"
                  variant="h6"
                  component="div"
                >
                  [エラー]<br />{errorMessage}
                </Typography>
              </div>
            )}
          </Container>
        </div>
      </div>
    ) : <></>
  );
});

