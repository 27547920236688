import React, { useState, useEffect } from 'react';
import firebase, { auth } from '~/firebase';
import { gapi, init, runScript } from '~/appsscript';
import { functions } from '~/firebase';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core/styles';
import * as Colors from '@material-ui/core/colors';
import {
  Backdrop,
  CircularProgress,
} from '@material-ui/core';
import { Entrance } from '~/routes/Entrance';
import { Admin } from '~/routes/Admin';
import { Top } from '~/routes/Top';
import { SignIn } from '~/components/SignIn';
import logo from './logo.svg';
import './App.css';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.teal[500]
    }
  }
});

export const App = () => {
  const [ gapiInitialized, setGapiInitialized ] = useState(false);
  const [ userLoaded, setUserLoaded ] = useState(false);
  const [ user, setUser ] = useState(null);
  const [ hasAccess, setHasAccess ] = useState(null);
  const [ signInProcessing, setSignInProcessing ] = useState(false);
  console.log([
    `gapi: ${gapiInitialized ? 'Initialized' : 'Waiting...'}`,
    `userLoaded: ${userLoaded ? 'Loaded' : 'Waiting...'}`,
    `user: ${userLoaded ? (user ? 'Signed In' : 'No User') : 'Waiting...'}`,
    `hasAccess: ${hasAccess !== null ? hasAccess : 'Waiting...'}`,
  ].join('\n'));

  useEffect(() => {
    document.title = "参加者管理"
    auth.onAuthStateChanged(user => {
      setUserLoaded(true);
      setUser(user);
    });
    init().then(() => {
      setGapiInitialized(true);
      setUserLoaded(true);
      const isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get()
      if(isSignedIn) authWithFirebase();
    });
  }, []);

  const checkPermission = async permission => {
    if(!permission) {
      return new Promise(resolve => {
        setHasAccess(true);
        resolve(true);
      });
    }
    setHasAccess(null);
    return runScript({
      functionName: 'authorize',
      parameters: [ 'STAFF', true ]
    }).then(() => {
      setHasAccess(true);
      return true;
    }).catch(err => {
      switch(err.message) {
        case 'auth/no-access':
          setHasAccess(false);
          return false;
        default:
          throw err;
      }
    });
  };

  const signIn = () => {
    setSignInProcessing(true);
    gapi.auth2.getAuthInstance().signIn({
      prompt: 'select_account'
    }).then(() => {
      setSignInProcessing(false);
      const isSignedIn = gapi.auth2.getAuthInstance().isSignedIn.get()
      if(isSignedIn) authWithFirebase();
    }).catch(err => {
      setSignInProcessing(false);
      console.error(err);
    });
  };
  const signOut = () => {
    auth.signOut();
    gapi.auth2.getAuthInstance().signOut();
  };

  const authWithFirebase = () => {
    const googleUser = gapi.auth2.getAuthInstance().currentUser.get()
    if(!Boolean(googleUser)) return false;
    const idToken = googleUser.getAuthResponse().id_token;
    const creds = firebase.auth.GoogleAuthProvider.credential(idToken);
    auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL).then(() => {
      firebase.auth().signInWithCredential(creds).then(user => {
        const googleProfile = googleUser.getBasicProfile();
        if(user) console.log(JSON.stringify(googleProfile, null, 2));
      });
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        {gapiInitialized && userLoaded && (
          Boolean(user) && (hasAccess !== false) ? (
            <Router>
              <Switch>
                <Route exact path="/entrance" render={props => (
                  <Entrance
                    title="サークル入場 QR リーダ"
                    checkPermission={checkPermission}
                    hasAccess={hasAccess}
                    {...props}
                  />
                )} />
                <Route path="/admin" render={props => (
                  <Admin
                    title="サークル参加管理"
                    checkPermission={checkPermission}
                    hasAccess={hasAccess}
                    {...props}
                  />
                )} />
                <Route render={props => (
                  <Top
                    checkPermission={checkPermission}
                    hasAccess={hasAccess}
                    user={user}
                    signOut={signOut}
                    signIn={signIn}
                    {...props}
                  />
                )} />
              </Switch>
            </Router>
          ) : (
            <SignIn
              signIn={signIn}
              user={user}
              hasAccess={hasAccess}
              open={!signInProcessing}
            />
          )
        )}
        
        {(!gapiInitialized || !userLoaded || (Boolean(user) && hasAccess === null) || signInProcessing) && (
          <Backdrop open={true} style={{ zIndex: 2000 }}>
            <CircularProgress style={{ color: '#fff' }} />
          </Backdrop>
        )}
      </ThemeProvider>
    </>
  );
};

