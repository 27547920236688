import React from 'react';
import {
  Button,
  Dialog, DialogContent, DialogContentText,
  Typography,
} from '@material-ui/core';
import { StandardDialog } from '~/components/StandardDialog';

export const SignIn = ({
  signIn,
  user,
  hasAccess,
  open,
}) => {
  return (
    <StandardDialog
      title="ログイン"
      submitLabel={Boolean(user) ? 'アカウントを変更' : 'ログインする'}
      description="システムへのアクセスには Google アカウントでのログインが必要です."
      onSubmit={signIn}
      onCancel={() => window.location.href = '/'}
      open={open}
    >
      {hasAccess === false && (
        <Typography variant="inherit" color="error" paragraph>
          ログインしたアカウント ({user.email}) にはアクセス権限がありません.
        </Typography>
      )}
    </StandardDialog>
  );
};

