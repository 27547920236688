import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { auth } from '~/firebase';
import { gapi } from '~/appsscript';
import {
  Grid, Container, Toolbar,
  Card, CardActionArea, Paper as MuiPaper,
  Button,
  Typography,
  Avatar,
} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';

const Paper = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  }
}))(MuiPaper);

const useStyle = makeStyles(theme => ({
  cardActionArea: {
    padding: theme.spacing(2)
  },
  avatar: {
    width: theme.spacing(8),
    height: theme.spacing(8),
  },
  userDetails: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center'
  }
}));

const items = [{
  path: '/entrance',
  label: 'サークル入場 QR リーダ',
  permission: 'Guest'
}, {
  path: '/admin',
  label: 'サークル参加管理',
  permission: 'Staff'
}];

export const Top = withRouter(({
  checkPermission,
  hasAccess,
  history,
  user,
  signOut,
  signIn,
  ...props
}) => {
  useEffect(() => {
    checkPermission();
  }, []);

  const linkTo = history.push;

  const classes = useStyle();
  return (
    hasAccess ? (
      <>
        <Container maxWidth="sm">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              component="h1"
            >
              システムを選択
            </Typography>
          </Toolbar>
          <Grid
            container
            justify="center"
            spacing={2}
          >
            {items.map(({ path, label, permission }) => (
              <Grid item xs={12} sm={6} key={path}>
                <Card>
                  <CardActionArea
                    onClick={() => linkTo(path)}
                    className={classes.cardActionArea}
                  >
                    <Typography
                      variant="h6"
                      component="h3"
                    >
                      {label}
                    </Typography>
                    <Typography
                      variant="subtitle1"
                    >
                      権限: [{permission}]
                    </Typography>
                  </CardActionArea>
                </Card>
              </Grid>
            ))}
          </Grid>

          <Toolbar disableGutters>
            <Typography
              variant="h6"
              component="h1"
            >
              アカウント
            </Typography>
          </Toolbar>
          <Paper
            variant="outlined"
          >
            <Grid
              container
              spacing={2}
              direction="column"
            >
              <Grid item>
                <Grid container spacing={1} justify="center">
                  <Grid item>
                    <Avatar
                      alt={user.displayName}
                      src={user.photoURL}
                      className={classes.avatar}
                    />
                  </Grid>
                  <Grid
                    item
                    style={{ display: 'flex' }}
                    direction="column"
                    justify="center"
                  >
                    <Typography variant="body1">
                      {user.displayName}
                    </Typography>
                    <Typography variant="body2">
                      {user.email}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item style={{ display: 'flex' }} alignItems="center">
                <Grid container spacing={1}>
                  <Grid item xs>
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      onClick={signOut}
                    >
                      ログアウト
                    </Button>
                  </Grid>
                  <Grid item xs>
                    <Button
                      variant="outlined"
                      size="small"
                      fullWidth
                      onClick={signIn}
                    >
                      アカウント切替
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Container>
      </>
    ) : <></>
  );
});

