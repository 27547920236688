import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  List, ListItem, ListItemText,
  Collapse,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

const useStyle = makeStyles(theme => ({
  collapsePaddingLeft: {
    paddingLeft: theme.spacing(2)
  },
  collapsePaddingBoth: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  bottomSpace: {
    height: theme.spacing(4)
  }
}));

export const CollapseListItem = ({
  primary,
  secondary,
  open,
  padding = 'left',
  onItemClick,
  children
}) => {
  const classes = useStyle();
  return (
    <>
      <ListItem
        button
        onClick={onItemClick}
      >
        <ListItemText primary={primary} secondary={secondary} />
        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
      </ListItem>
      <Collapse
        in={open}
        className={function() {
          switch(padding) {
            case 'both': return classes.collapsePaddingBoth;
            default: return classes.collapsePaddingLeft;
          }
        }()}
      >
        {children}
        <div className={classes.bottomSpace} />
      </Collapse>
    </>
  );
};

