import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar, Toolbar, Button, CircularProgress,
  Tabs, Tab,
  Menu, MenuItem,
  List, ListItem, ListItemText,
  Divider,
} from '@material-ui/core';
import { CircleDetails } from '~/components/CircleDetails';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';

const useStyle = makeStyles(theme => ({
  buttonWrap: {
    display: 'inline-flex',
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  }
}));

export const CircleList = ({
  circlesOpenStatus,
  circlesWithArchiveStatus,
  sortBy,
  onSortMethodChanged,
  setCircleIsOpen,
  buildSpaceName,
  updateCircle,
  archiveCircle,
  loadContent,
  loadingContent,
  openCircleId,
}) => {
  const [ sortMenuAnchorEl, setSortMenuAnchorEl ] = useState(null);
  const [ selectedList, setSelectedList ] = useState(0);

  // Sort
  const sort = sortMethodName => {
    onSortMethodChanged(sortMethodName);
    setSortMenuAnchorEl(null);
  };

  const handleSelectedListChange = (e, newValue) => setSelectedList(newValue);

  const classes = useStyle();
  return (
    <>
      <AppBar position="sticky" color="inherit" elevation={0}>
        <Toolbar disableGutters={true}>
          <div className={classes.buttonWrap}>
            <Button
              startIcon={<RefreshIcon />}
              color="primary"
              disabled={loadingContent}
              onClick={loadContent}
            >
              更新
            </Button>
            {loadingContent && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
          <div style={{ flexGrow: 1 }} />
          <Button
            aria-controls="sort-menu"
            aria-haspopup="true"
            onClick={(e) => setSortMenuAnchorEl(e.currentTarget)}
            startIcon={<FilterListIcon />}
          >
            {{
              'name': 'サークル名',
              'paid': '参加費支払状況',
              'space': '配置場所'
            }[sortBy]}
          </Button>
          <Menu
            id="sort-menu"
            keepMounted
            anchorEl={sortMenuAnchorEl}
            open={Boolean(sortMenuAnchorEl)}
            onClose={() => setSortMenuAnchorEl(null)}
          >
            <MenuItem onClick={() => {sort('name')}}>サークル名</MenuItem>
            <MenuItem onClick={() => {sort('paid')}}>参加費支払状況</MenuItem>
            <MenuItem onClick={() => {sort('space')}}>配置場所</MenuItem>
          </Menu>
        </Toolbar>
        <Tabs
          value={selectedList}
          onChange={handleSelectedListChange}
          indicatorColor="primary"
          textColor="primary"
          variant="fullWidth"
        >
          <Tab label="参加" />
          <Tab label="不参加" />
        </Tabs>
      </AppBar>

      {circlesWithArchiveStatus === null ? (
        <div>Loading...</div>
      ) : circlesWithArchiveStatus.map((circle_list, index) => (
        <List dense hidden={selectedList !== index} key={index}>
          <Divider />
          {circle_list.map(circle => {
            const { id, name, paid, space, representative } = circle;
            return (
              <>
                <ListItem
                  key={id}
                  button
                  onClick={() => setCircleIsOpen(id, true)}
                >
                  <ListItemText
                    primary={name}
                    secondary={
                      (paid ? '支払済み' : '支払待ち')
                      + ' ･ ' + representative.penName
                      + ' ･ ' + buildSpaceName(space)
                    }
                  />
                </ListItem>
                <Divider />
              </>
            );
          })}
        </List>
      ))}

      {circlesWithArchiveStatus && circlesWithArchiveStatus.map(circle_list => (
        circle_list.map(circle => {
          const { id } = circle;
          return (
            <CircleDetails
              key={id}
              circle={circle}
              open={id === openCircleId}
              onSubmit={updateCircle}
              onArchive={archiveCircle}
              onClose={() => setCircleIsOpen(id, false)}
            />
          )
        })
      ))}
    </>
  );
};

