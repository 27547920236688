import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
  Dialog, DialogContent, DialogContentText,
  AppBar, Toolbar,
  IconButton, Typography,
  Slide,
  useMediaQuery, useScrollTrigger,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const CoverDialog = ({
  open,
  onClose = null,
  onCancel = onClose,
  breakpoint = 'sm',
  title,
  actions,
  description,
  children,
}) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down(breakpoint));

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      fullScreen={fullScreen}
      onClose={onClose}
      keepMounted
    >
      <AppBar
        position="sticky"
        color="inherit"
        elevation={0}
      >
        <Toolbar>
          <IconButton onClick={onCancel}>
            <CloseIcon />
          </IconButton>
          {title && <Typography variant="h6" component="h1">{title}</Typography>}
          {actions && (
            <>
              <div style={{ flexGrow: 1 }} />
              {actions.map((ActionElement, index) => {
                const ActionComponent = props => ActionElement;
                return <ActionComponent key={index} />;
              })}
            </>
          )}
        </Toolbar>
      </AppBar>
      <DialogContent dividers>
        {description && <DialogContentText>{description}</DialogContentText>}
        {children}
      </DialogContent>
    </Dialog>
  );
};

