import React from 'react';
import { Toolbar } from '@material-ui/core';

export const Page = ({
  open=true,
  children,
}) => {
  return (
    <div
      hidden={!open}
    >
      {children}
      <Toolbar />
    </div>
  );
};

