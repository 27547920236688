import React from 'react';
import {
  Snackbar, IconButton,
  Slide,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const Alert = ({
  open,
  onClose,
  message,
  ...others
}) => {
  return (
    <Snackbar
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
      message={message}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      autoHideDuration={6000}
      { ...others }
      action={
        <IconButton
          size="small"
          onClick={onClose}
          color="inherit"
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
    />
  );
};

