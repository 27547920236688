import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Paper,
  BottomNavigation, BottomNavigationAction,
} from '@material-ui/core';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';

const useStyle = makeStyles(theme => ({
  root: {
    position: 'fixed',
    width: '100%',
    bottom: 0
  }
}));

export const AdminNav = ({
  value = 0,
  onChange,
}) => {
  const classes = useStyle();
  return (
    <Paper className={classes.root} elevation={8}>
      <BottomNavigation
        value={value}
        onChange={onChange}
        showLabels
      >
        <BottomNavigationAction
          label="概要"
          icon={<AssessmentOutlinedIcon />}
        />
        <BottomNavigationAction
          label="サークル"
          icon={<GroupWorkOutlinedIcon />}
        />
      </BottomNavigation>
    </Paper>
  );
};

