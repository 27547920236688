import firebase from 'firebase';
import 'firebase/auth';
import 'firebase/functions';

const config = {
  apiKey: "AIzaSyBIruTX_HfLHhLh1vCQ4wnXV2BnyRkztIs",
  authDomain: "touhou-freemarket.firebaseapp.com",
  databaseURL: "https://touhou-freemarket.firebaseio.com",
  projectId: "touhou-freemarket",
  storageBucket: "touhou-freemarket.appspot.com",
  messagingSenderId: "1036465059464",
  appId: "1:1036465059464:web:acef14830022b13924513d"
};

firebase.initializeApp(config);

export const auth = firebase.auth();

export const functions = firebase.functions();
functions.useFunctionsEmulator('http://localhost:5001')

export default firebase;

