import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';
import { runScript } from '~/appsscript';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Toolbar, Typography,
} from '@material-ui/core';
//import {  } from '@material-ui/core';
import { AdminNav } from '~/components/AdminNav';
import { AdminPageHome } from '~/components/AdminPageHome';
import { AdminPageCircles } from '~/components/AdminPageCircles';

export const Admin = withRouter(({
  checkPermission,
  hasAccess,
  history,
  location,
  title,
  ...props
}) => {
  const paths = [ '/admin', '/admin/circles' ];
  const indexOfPath = paths.indexOf(location.pathname);
  const defaultNavValue = indexOfPath > -1 ? indexOfPath : 1;
  const [ adminNavValue, setAdminNavValue ] = useState(defaultNavValue);

  useEffect(() => {
    checkPermission('STAFF');
    document.title = title;
  }, []);

  return (
    hasAccess ? (
      <>
        <Route exact path={paths[0]} render={props => (
          <AdminPageHome
            title={`概要 | ${title}`}
            open={true}
            {...props}
          />
        )} />
        <Route path={paths[1]} render={props => (
          <AdminPageCircles
            title={`サークル | ${title}`}
            open={true}
            {...props}
          />
        )} />
      
        <AdminNav
          value={adminNavValue}
          onChange={(e, value) => {
            setAdminNavValue(value);
            history.push(paths[value]);
          }}
        />
      </>
    ) : <></>
  );
});

