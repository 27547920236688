import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container, CircularProgress,
  AppBar, Toolbar, IconButton,
  Table, TableBody, TableRow, TableCell,
  Typography,
  TextField, Input, Select, MenuItem,
  Button,
  List, ListItem, ListItemText, Collapse,
  Link as MuiLink,
  Divider,
  FormGroup, FormControlLabel, Checkbox,
} from '@material-ui/core';
import { CoverDialog } from '~/components/CoverDialog';
import { StandardDialog } from '~/components/StandardDialog';
import { CollapseListItem } from '~/components/CollapseListItem';

const useStyle = makeStyles(theme => ({
  container: props => ({
    [theme.breakpoints.up(props.breakpoint)]: {
      width: '100vw'
    }
  }),
  buttonWrap: {
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  detailHead: {
    display: 'flex',
    justifyContent: 'center'
  },
  detailHeadTitle: {
    flexGrow: 1
  },
  detailHeadActions: {

  },
  archive: {
    color: theme.palette.error.main
  },
  unarchive: {
    color: theme.palette.primary.main
  },
  spaceblock: {
    width: theme.spacing(4)
  },
  spaceblockInput: {
    textAlign: 'center'
  },
  spacenumber: {
    width: theme.spacing(8)
  },
  spacenumberInput: {
    textAlign: 'center'
  },
  breakCell: {
    wordBreak: 'break-all',
  },
}));

const itemsAreOpenDefault = {
  space: true,
  paid: true,
  basic: true,
  typeDetails: true,
  representative: false,
};

export const CircleDetails = ({
  circle,
  open,
  onSubmit,
  onArchive,
  onClose,
}) => {
  const breakpoint = 'sm';
  const [ warnDialogIsOpen, setWarnDialogIsOpen ] = useState(false);
  const [ waitingForUpdate, setWaitingForUpdate] = useState(false);
  const [ waitingForUpdateArchive, setWaitingForUpdateArchive ] = useState(false);
  const [ itemsAreOpen, setItemsAreOpen ] = useState(itemsAreOpenDefault)
  const [ paid, setPaid ] = useState(circle.paid);
  const [ enableSpace, setEnableSpace ] = useState(circle.space.block !== '' && circle.space.number !== '');
  const [ spaceblock, setSpaceblock ] = useState(circle.space.block);
  const [ spacenumber, setSpacenumber ] = useState(circle.space.number);

  const formChanged = (
    paid !== circle.paid
    || enableSpace !== (circle.space.block !== '' && circle.space.number !== '')
    || spaceblock !== circle.space.block
    || spacenumber !== circle.space.number
  );


  const toggleItemOpen = target => {
    const itemsAreOpen_copy = { ...itemsAreOpen };
    itemsAreOpen_copy[target] = !itemsAreOpen_copy[target];
    setItemsAreOpen(itemsAreOpen_copy);
  }

  const resetForm = () => {
    setPaid(circle.paid);
    setEnableSpace(circle.space.block !== '' && circle.space.number !== '');
    setSpaceblock(circle.space.block);
    setSpacenumber(circle.space.number);
  };

  const handleClose = (e, force = false) => {
    if(!force && formChanged) return setWarnDialogIsOpen(true);
    resetForm();
    setItemsAreOpen(itemsAreOpenDefault);
    onClose();
  };

  const handleSubmit = () => {
    setWaitingForUpdate(true);
    const changes = {};
    if(paid !== circle.paid) changes['paid'] = paid;
    if(enableSpace !== (circle.space.block !== '' && circle.space.number !== '') && !enableSpace) {
      changes['space/block'] = '';
      changes['space/number'] = '';
    }
    else {
      if(spaceblock !== circle.space.block) changes['space/block'] = spaceblock;
      if(spacenumber !== circle.space.number) changes['space/number'] = spacenumber;
    }
    console.log(changes);
    onSubmit(circle.id, changes).then(() => setWaitingForUpdate(false));
  };

  const handleArchive = () => {
    setWaitingForUpdateArchive(true);
    onArchive(circle.id, !circle.archived).then(() => setWaitingForUpdateArchive(false));
  };


  // handleFormChanged
  const handlePaidChange = e => setPaid(Boolean(e.target.value));
  const handleEnableSpaceChange = e => setEnableSpace(Boolean(e.target.checked));
  const handleSpaceblockChange = e => setSpaceblock(String(e.target.value));
  const handleSpacenumberChange = e => setSpacenumber(Number(e.target.value));

  const classes = useStyle();
  return (
    <>
      {/* Details 本体 */}
      <CoverDialog
        open={open}
        onCancel={handleClose}
        breakpoint={breakpoint}
        actions={[(
          <div className={classes.buttonWrap}>
            <Button
              variant="contained"
              color="primary"
              disabled={!formChanged || waitingForUpdate}
              onClick={handleSubmit}
            >
              変更を保存
            </Button>
            {waitingForUpdate && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        )]}
      >
        <Container
          maxWidth="xs"
          className={classes.container}
        >
          <div className={classes.detailHead}>
            <div className={classes.detailHeadTitle}>
              <Typography variant="h5" component="h4">
                {circle.name}
              </Typography>
              <Typography color="textSecondary" gutterBottom>
                {circle.representative.penName}
              </Typography>
            </div>
            <div className={classes.detailHeadActions}>
              <div className={classes.buttonWrap}>
                <Button
                  className={circle.archived ? classes.unarchive : classes.archive}
                  disabled={waitingForUpdateArchive}
                  onClick={handleArchive}
                >
                  {circle.archived ? '参加に戻す' : '不参加にする'}
                </Button>
                {waitingForUpdateArchive && <CircularProgress size={24} className={classes.buttonProgress} />}
              </div>
            </div>
          </div>
          <Divider />

          <List dense>
            <CollapseListItem
              primary="配置場所"
              open={itemsAreOpen.space}
              onItemClick={() => toggleItemOpen('space')}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={enableSpace}
                    onChange={handleEnableSpaceChange}
                    name="enableSpace"
                    color="primary"
                  />
                }
                label="配置する"
              />
              <FormGroup row>
                <Input
                  value={spaceblock}
                  disabled={!enableSpace}
                  onChange={handleSpaceblockChange}
                  classes={{ root: classes.spaceblock, input: classes.spaceblockInput }}
                />
                <span> - </span>
                <Input
                  value={spacenumber}
                  disabled={!enableSpace}
                  onChange={handleSpacenumberChange}
                  type="number"
                  classes={{ root: classes.spacenumber, input: classes.spacenumberInput }}
                />
              </FormGroup>
            </CollapseListItem>

            <CollapseListItem
              primary="参加費支払状況"
              open={itemsAreOpen.paid}
              onItemClick={() => toggleItemOpen('paid')}
            >
              <Select
                value={paid}
                onChange={handlePaidChange}
              >
                <MenuItem value={true}>支払済み</MenuItem>
                <MenuItem value={false}>支払待ち</MenuItem>
              </Select>
            </CollapseListItem>

            <CollapseListItem
              primary="サークル基本情報"
              open={itemsAreOpen.basic}
              onItemClick={() => toggleItemOpen('basic')}
            >
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>サークル名</TableCell>
                    <TableCell>
                      <ruby>
                        {circle.name}
                        <rp><br />(</rp>
                        <rt>{circle.nameKana}</rt>
                        <rp>)</rp>
                      </ruby>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>参加形式</TableCell>
                    <TableCell colSpan={2}>
                      {{
                        exhibition: '展示',
                        demonstration: '実演',
                        distribution: '頒布'
                      }[circle.type]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>希望スペース数</TableCell>
                    <TableCell colSpan={2}>
                      <Typography variant="inherit">{circle.spaceNum} スペース</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>合体希望サークル名</TableCell>
                    <TableCell colSpan={2}>
                      <Typography variant="inherit">{circle.combine || '(なし)'}</Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>机</TableCell>
                    <TableCell colSpan={2}>{circle.desk ? (
                      <Typography variant="inherit">あり</Typography>
                    ) : (
                      <Typography color="error" variant="inherit">なし</Typography>
                    )}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>椅子</TableCell>
                    <TableCell colSpan={2}>{circle.chair ? (
                      <Typography variant="inherit">あり</Typography>
                    ) : (
                      <Typography color="error" variant="inherit">なし</Typography>
                    )}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>ホームページ</TableCell>
                    <TableCell
                      className={classes.breakCell}
                    >
                      {circle.website.url !== '' ? (
                        <MuiLink
                          target="_blank"
                          href={circle.website.url}
                        >
                          {circle.website.url}
                        </MuiLink>
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                    <TableCell>
                      {circle.website.url !== '' ? (
                        circle.website.isPublic ? (
                          <Typography variant="inherit">公開</Typography>
                        ) : (
                          <Typography color="error" variant="inherit">非公開</Typography>
                        )
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>Twitter</TableCell>
                    <TableCell>
                      {circle.twitter.id !== '' ? (
                        <MuiLink
                          target="_blank"
                          href={`https://twitter.com/${circle.twitter.id}`}
                        >
                          @{circle.twitter.id}
                        </MuiLink>
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                    <TableCell>
                      {circle.twitter.id !== '' ? (
                        circle.twitter.isPublic ? (
                          <Typography variant="inherit">公開</Typography>
                        ) : (
                          <Typography color="error" variant="inherit">非公開</Typography>
                        )
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>pixiv</TableCell>
                    <TableCell>
                      {circle.pixiv.id !== '' ? (
                        <MuiLink
                          target="_blank"
                          href={`https://www.pixiv.net/users/${circle.pixiv.id}`}
                        >
                          {circle.pixiv.id}
                        </MuiLink>
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                    <TableCell>
                      {circle.pixiv.id !== '' ? (
                        circle.pixiv.isPublic ? (
                          <Typography variant="inherit">公開</Typography>
                        ) : (
                          <Typography color="error" variant="inherit">非公開</Typography>
                        )
                      ) : (
                        <span>-</span>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CollapseListItem>

            <CollapseListItem
              primary="サークル参加詳細"
              open={itemsAreOpen.typeDetails}
              onItemClick={() => toggleItemOpen('typeDetails')}
            >
              {function() {
                switch(circle.type) {
                  case 'exhibition':
                    const { exhibition } = circle;
                    return (
                      <>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell>電源の使用</TableCell>
                              <TableCell>
                                {exhibition.powerSupply ? (
                                  <Typography color="error" variant="inherit">希望する</Typography>
                                ) : (
                                  <Typography variant="inherit">希望しない</Typography>
                                )}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>R18 作品の展示</TableCell>
                              <TableCell>
                                {exhibition.hasR18 ? (
                                  <Typography color="error" variant="inherit">あり</Typography>
                                ) : (
                                  <Typography variant="inherit">なし</Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <br />
                        <Typography variant="subtitle2">展示内容詳細</Typography>
                        <Typography>
                          {exhibition.detail.split('\n').map(row => (<><span>{row}</span><br /></>))}
                        </Typography>
                      </>
                    );
                  case 'demonstration':
                    const { demonstration } = circle;
                    return (
                      <>
                        <Table size="small">
                          <TableBody>
                            <TableRow>
                              <TableCell>電源の使用</TableCell>
                              <TableCell>
                                {demonstration.powerSupply ? (
                                  <Typography color="error" variant="inherit">希望する</Typography>
                                ) : (
                                  <Typography variant="inherit">希望しない</Typography>
                                )}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                        <br />
                        <Typography variant="subtitle2">実演内容詳細</Typography>
                        <Typography>
                          {demonstration.detail.split('\n').map(row => (<><span>{row}</span><br /></>))}
                        </Typography>

                      </>
                    );
                  case 'distribution':
                    const { distribution } = circle;
                    return (
                      <Table size="small">
                        <TableBody>
                          <TableRow>
                            <TableCell>メインとなる頒布物のジャンル</TableCell>
                            <TableCell>
                              {(() => {
                                const fromPreset = {
                                  doujinshi: '同人誌',
                                  goods: 'グッズ',
                                  media: '電子メディア'
                                }[distribution.genre];
                                return fromPreset || distribution.genre;
                              })()}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>メイン作品名</TableCell>
                            <TableCell>{distribution.mainWork}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>配置希望キャラクター</TableCell>
                            <TableCell>{distribution.character}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>予定持ち込み部数・個数 (総数)</TableCell>
                            <TableCell>{distribution.amount}</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>R18 作品</TableCell>
                            <TableCell>
                              {distribution.hasR18 ? (
                                <Typography color="error" variant="inherit">あり</Typography>
                              ) : (
                                <Typography variant="inherit">なし</Typography>
                              )}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    );
                }
              }()}
            </CollapseListItem>
            
            <CollapseListItem
              primary="代表者情報"
              padding="both"
              open={itemsAreOpen.representative}
              onItemClick={() => toggleItemOpen('representative')}
            >
              <Table size="small">
                <TableBody>
                  <TableRow>
                    <TableCell>ペンネーム</TableCell>
                    <TableCell>{circle.representative.penName}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>氏名</TableCell>
                    <TableCell>{circle.representative.name}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>性別</TableCell>
                    <TableCell>
                      {{
                        M: '男性',
                        F: '女性'
                      }[circle.representative.gender]}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>生年月日</TableCell>
                    <TableCell>
                      {circle.representative.birthday.fullYear}/{('0'+circle.representative.birthday.month).slice(-2)}/{('0'+circle.representative.birthday.date).slice(-2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>メールアドレス</TableCell>
                    <TableCell>
                      <MuiLink
                        target="_blank"
                        href={`mailto:${circle.representative.email}`}
                      >
                        {circle.representative.email}
                      </MuiLink>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>電話番号</TableCell>
                    <TableCell>
                      <MuiLink
                        target="_blank"
                        href={`tel:${circle.representative.phoneNumber}`}
                      >
                        {circle.representative.phoneNumber}
                      </MuiLink>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>住所</TableCell>
                    <TableCell>{circle.representative.address}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>コスプレ</TableCell>
                    <TableCell>{circle.representative.cosplay ? 'する' : 'しない'}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </CollapseListItem>
          </List>
        </Container>
      </CoverDialog>

      {/* 破棄確認 */}
      <StandardDialog
        open={warnDialogIsOpen}
        onCancel={() => setWarnDialogIsOpen(false)}
        onSubmit={e => {
          handleClose(e, true);
          setWarnDialogIsOpen(false);
        }}
        title="変更を破棄しますか？"
        description={(
          <>
            変更がまだ保存されていません.
            <br />このまま閉じると変更内容は保存されず, 変更前の状態に戻ります. 変更を破棄して閉じますか？
          </>
        )}
        submitLabel="破棄して閉じる"
        dangerSubmit
      />
    </>
  );
};

