import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { runScript } from '~/appsscript';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Toolbar,
  Typography,
  Container,
  Paper as MuiPaper,
  IconButton, Button, CircularProgress,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Page } from '~/components/Page';

const Paper = withStyles(theme => ({
  root: {
    padding: theme.spacing(2)
  }
}))(MuiPaper);

const useStyle = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2)
  },

  buttonWrap: {
    display: 'inline-flex',
    margin: theme.spacing(1),
    position: 'relative'
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12
  },
  flexWrap: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  progressWrap: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    position: 'relative'
  },
  progress: {
  },
  progressValue: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)'
  }
}));

export const AdminPageHome = withRouter(({
  title,
  open,
}) => {
  const [ loadingContent, setLoadingContent ] = useState(false);
  const [ overview, setOverview ] = useState(null);

  const loadContent = () => {
    setLoadingContent(true);
    runScript({ functionName: 'getOverview' }).then(data => {
      setLoadingContent(false);
      setOverview(data);
      console.log(data)
    });
  };

  useEffect(() => {
    if(!open) return;
    loadContent();
    document.title = title;
  }, []);

  const spaceNum = overview ? overview['spaceNum/exhibition'] + overview['spaceNum/demonstration'] + overview['spaceNum/distribution'] : null;

  const classes = useStyle();
  return (
    <Page open={open}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.backButton}
          color="inherit"
          onClick={() => window.location.href = '/'}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="h1">
          概要
        </Typography>
      </Toolbar>
      <Container maxWidth="sm">
        <div className={classes.buttonWrap}>
          <Button
            startIcon={<RefreshIcon />}
            color="primary"
            disabled={loadingContent}
            onClick={loadContent}
          >
            更新
          </Button>
          {loadingContent && <CircularProgress size={24} className={classes.buttonProgress} />}
        </div>
        <Paper variant="outlined">
          <Typography variant="h6" component="h2" gutterBottom>申込状況</Typography>
          {overview ? (
            <>
              <div className={classes.flexWrap}>
                <div>
                  <Typography variant="subtitle1" component="h3" gutterBottom>展示</Typography>
                  <div>
                    <Typography variant="body1" component="span">{overview['circleNum/exhibition']}</Typography>
                    <Typography variant="body2" component="span"> サークル</Typography>
                  </div>
                  <div>
                    <Typography variant="body1" component="span">{overview['spaceNum/exhibition']}</Typography>
                    <Typography variant="body2" component="span"> スペース</Typography>
                  </div>
                </div>
                <div>
                  <Typography variant="subtitle1" component="h3" gutterBottom>実演</Typography>
                  <div>
                    <Typography variant="body1" component="span">{overview['circleNum/demonstration']}</Typography>
                    <Typography variant="body2" component="span"> サークル</Typography>
                  </div>
                  <div>
                    <Typography variant="body1" component="span">{overview['spaceNum/demonstration']}</Typography>
                    <Typography variant="body2" component="span"> スペース</Typography>
                  </div>
                </div>
                <div>
                  <Typography variant="subtitle1" component="h3" gutterBottom>頒布</Typography>
                  <div>
                    <Typography variant="body1" component="span">{overview['circleNum/distribution']}</Typography>
                    <Typography variant="body2" component="span"> サークル</Typography>
                  </div>
                  <div>
                    <Typography variant="body1" component="span">{overview['spaceNum/distribution']}</Typography>
                    <Typography variant="body2" component="span"> スペース</Typography>
                  </div>
                </div>
              </div>
              <div className={classes.progressWrap}>
                <CircularProgress
                  variant="static"
                  value={spaceNum / overview['maxSpaceNum'] * 100}
                  size={128}
                  className={classes.progress}
                />
                <div className={classes.progressValue}>
                  <Typography variant="body1" component="span">{spaceNum}</Typography>
                  <Typography variant="body2" component="span"> / {overview['maxSpaceNum']}</Typography>
                </div>
              </div>
            </>
          ) : (
            <div>Loading...</div>
          )}
        </Paper>
      </Container>
    </Page>
  );
});

