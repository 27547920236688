const scriptId = 'MSfurUuOCIbRs36xsiV8rP7lZ65QzUdlZ';

export const gapi = window.gapi;
const config = {
  apiKey: /*'AIzaSyAPFoy23WnVhcuJkf_vwA07F41s_mJMU_0'*/'AIzaSyBIruTX_HfLHhLh1vCQ4wnXV2BnyRkztIs',
  clientId: '1036465059464-ug6t49ra30pvd89ee7pjl52h5k6guvng.apps.googleusercontent.com',
  discoveryDocs: [
    'https://sheets.googleapis.com/$discovery/rest?version=v4',
    'https://script.googleapis.com/$discovery/rest?version=v1'
  ],
  scope: [
    'email', 'profile', 'openid',
    'https://mail.google.com/',
    'https://www.googleapis.com/auth/spreadsheets',
    'https://www.googleapis.com/auth/script.projects'
  ].join(' ')
};

export const init = () => {
  return new Promise((resolve, reject) => {
    window.gapi.load('client:auth2', () => {
      window.gapi.client.init(config).then(resolve).catch(reject);
    });
  });
};

export const runScript = ({ functionName, parameters = [] }) => {
  return gapi.client.script.scripts.run({
    scriptId,
    resource: {
      function: functionName,
      parameters
    }
  }).then(resp => {
    if(resp.result.error) throw new Error(resp.result.error.details[0].errorMessage);
    return resp.result.response.result;
  });
};

