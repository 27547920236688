import React, { useState, useEffect }  from 'react';
import { Route, withRouter } from 'react-router-dom';
import { runScript } from '~/appsscript';
import {
  Container,
  Toolbar, Typography,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Page } from '~/components/Page';
import { CircleList } from '~/components/CircleList';
import { Alert } from '~/components/Alert';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

/*const dummyCircles = [
  {
    id: '0001',
    name: 'Stardust Sorcery',
    nameKana: 'すたーだすとそーさりー',
    type: 'distribution',
    paid: false,
    distribution: {
      genre: '同人誌',
      mainWork: '東方紅魔郷',
      character: '霧雨魔理沙',
      amount: 50,
      hasR18: true
    },
    space: { block: '', number: '' },
    website: { url: 'https://ggtk.app', isPublic: true },
    twitter: { id: 'TakumaNitori', isPublic: false },
    pixiv: { id: '', isPublic: false },
    representative: {
      name: '中溝 悠太',
      penName: 'ぐぐたく',
      birthday: { fullYear: 1998, month: 12, date: 31 },
      email: 'yuta.nakamizo@ggtk.app'
    }
  },
  {
    id: '0002',
    name: 'Sample 1',
    nameKana: 'さんぷるいち',
    paid: true,
    space: { block: 'B', number: 3 },
    website: { url: 'https://ggtk.app', isPublic: true },
    twitter: { id: 'TakumaNitori', isPublic: false },
    pixiv: { id: '', isPublic: false },
    representative: {
      name: 'Sample Renpresentative',
      penName: 'ggtk',
      birthday: { fullYear: 2000, month: 1, date: 4 }
    }
  }
];*/

const useStyle = makeStyles(theme => ({
  backButton: {
    marginRight: theme.spacing(2)
  }
}));

export const AdminPageCircles = withRouter(({
  title,
  history, // from withRouter
  location, // from withRouter
  open,
}) => {
  const [ loadingContent, setLoadingContent ] = useState(false);
  const [ circlesOpenStatus, setCirclesOpenStatus ] = useState(null);
  const [ circlesWithArchiveStatus, setCirclesWithArchiveStatus ] = useState(null);
  const [ sortBy, setSortBy ] = useState('name');
  const [ saveSuccessAlertIsOpen, setSaveSuccessAlertIsOpen ] = useState(false);
  const [ saveFailureAlertIsOpen, setSaveFailureAlertIsOpen ] = useState(false);
  const [ openCircleId, setOpenCircleId ] = useState(location.pathname.slice(1).split('/')[2]);

  const loadContent = () => {
    setLoadingContent(true);
    runScript({ functionName: 'listCirclesWithArchiveStatus' }).then(circle_list => {
      const openStatus = circlesOpenStatus || {};
      console.log({ ...openStatus })
      for(let archivedGroup of circle_list) {
        for(let circle of archivedGroup) {
          const { id } = circle;
          if(openStatus[id] === undefined) openStatus[id] = false;
        }
      }
      setCirclesOpenStatus(openStatus);
      handleSortMethodChanged(sortBy, circle_list/*dummyCircles*/);
      setLoadingContent(false);
    }).catch(console.error);
  };

  useEffect(() => {
    if(!open) return;
    loadContent();
    document.title = title;
  }, []);

  const buildSpaceName = ({ block, number }, length = 2) => (block === '' || number === '') ? '(未配置)' : `${block}-${(new Array(length).fill(0).join('') + number).slice(-length)}`;

  const handleSortMethodChanged = (sortMethodName, circles_override) => {
    const sortMethods = {
      'name': {
        function: ({ nameKana: name1 }, { nameKana: name2 }) => (name1 > name2 ? 1 : -1),
        pre: [ 'space' ]
      },
      'paid': {
        function: ({ paid: paid1 }, { paid: paid2 }) => (paid1 < paid2 ? 1 : -1),
        pre: [ 'space', 'name' ]
      },
      'space': {
        function: ({ space: space1 }, { space: space2 }) => (buildSpaceName(space1) > buildSpaceName(space2) ? 1 : -1),
        pre: [ 'paid', 'name' ]
      }
    };
    const sorted = circles_override || circlesWithArchiveStatus;
    const method = sortMethods[sortMethodName];
    for(let i = 0; i < sorted.length; i++) {
      for(let preSortMethodName of method.pre) {
        sorted[i] = sorted[i].sort(sortMethods[preSortMethodName].function);
      }
      sorted[i] = sorted[i].sort(method.function)
    }
    setCirclesWithArchiveStatus(sorted);
    setSortBy(sortMethodName);
  };

  const handleCircleIsOpen = (id, open) => {
    console.log(id, open);
    const isFromUserAction = open ? null : history.location.state?.fromUserAction;
    console.log(isFromUserAction);
    open ? history.push(`/admin/circles/${id}`, { fromUserAction: true }) : (
      isFromUserAction ? history.goBack() : history.replace('/circles')
    );
  };

  history.listen(() => {
    const id = history.location.pathname.slice(1).split('/')[2];
    console.log(id);
    setOpenCircleId(id);
    /*const circlesOpenStatus_copy = { ...circlesOpenStatus };
    circlesOpenStatus_copy[id] = open;
    setCirclesOpenStatus(circlesOpenStatus_copy);*/
  })

  const updateCircle = (id, changes) => {
    return new Promise((resolve, reject) => {
      console.log(id, changes);
      runScript({
        functionName: 'updateCircle',
        parameters: [ id, changes ]
      }).then(updated_circle => {
        const { archived } = updated_circle;
        const circlesWithArchiveStatus_copy = [];
        for(let circle_list of circlesWithArchiveStatus) {
          const index = circle_list.findIndex(circle => circle.id === id)
          const circle_list_copy = [ ...circle_list ];
          if(index < 0) {
            circlesWithArchiveStatus_copy.push(circle_list_copy);
            continue;
          }
          circle_list_copy.splice(index, 1);
          circlesWithArchiveStatus_copy.push(circle_list_copy);
        }
        circlesWithArchiveStatus_copy[archived ? 1 : 0].push(updated_circle);
        console.log(circlesWithArchiveStatus_copy)
        handleSortMethodChanged(sortBy, circlesWithArchiveStatus_copy);
        resolve();
        setSaveSuccessAlertIsOpen(true);
      }).catch(err => {
        console.error(err);
        reject(err);
        setSaveFailureAlertIsOpen(true);
      });
    });
  };

  const archiveCircle = async (id, archive) => {
    await updateCircle(id, { archived: archive });
  };

  const classes = useStyle();
  return (
    <Page open={open}>
      <Toolbar>
        <IconButton
          edge="start"
          className={classes.backButton}
          color="inherit"
          onClick={() => window.location.href = '/'}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h6" component="h1">
          サークル
        </Typography>
      </Toolbar>
      <Container maxWidth="sm">
        <CircleList
          circlesOpenStatus={circlesOpenStatus}
          circlesWithArchiveStatus={circlesWithArchiveStatus}
          sortBy={sortBy}
          onSortMethodChanged={handleSortMethodChanged}
          setCircleIsOpen={handleCircleIsOpen}
          buildSpaceName={buildSpaceName}
          updateCircle={updateCircle}
          archiveCircle={archiveCircle}
          loadContent={loadContent}
          loadingContent={loadingContent}
          openCircleId={openCircleId}
        />
      </Container>

      {/* 保存フィードバック */}
      <Alert
        open={saveSuccessAlertIsOpen}
        message="変更を保存しました."
        onClose={() => setSaveSuccessAlertIsOpen(false)}
      />
      <Alert
        open={saveFailureAlertIsOpen}
        message="保存時にエラーが発生しました."
        onClose={() => setSaveFailureAlertIsOpen(false)}
      />
    </Page>
  );
});

